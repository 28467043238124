import type { ReactNode } from 'react';
import type { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCog, faCommentAltSmile } from '@fortawesome/pro-solid-svg-icons';

import Hexagon from 'components/display/Hexagon';
import Icon from 'components/display/Icon';
import routes from 'constants/routes';
import facts from 'constants/facts';

import LinkButton from './LinkButton';

import styles from './LearnMore.module.scss';

type LearnMoreCardProps = {
  title: string;
  description: string;
  link: string;
  href: string;
  icon: IconProp;
  className?: string;
};

const LearnMoreCard = (props: LearnMoreCardProps) => {
  const { title, description, link, href, icon, className = '' } = props;

  return (
    <div className={`${styles.card} ${className}`}>
      <Hexagon className={styles.hexagon} width={100} strokeWidth={0.4}>
        <Icon icon={icon} className={styles.hexIcon} />
      </Hexagon>
      <div className={styles.image} />
      <h6 className={styles.heading}>{title}</h6>
      <div className={styles.description}>{description}</div>
      <LinkButton href={href} className={styles.link} hasArrow={true}>
        {link}
      </LinkButton>
    </div>
  );
};

const defaultRenderCards = () => (
  <>
    <LearnMoreCard
      title='See How It Works'
      description='From price comparison to order management, learn all the ways Vetcove can be used to simplify your purchasing.'
      link='Learn More'
      href={routes.marketplace.clinics}
      icon={faCog}
    />
    <LearnMoreCard
      title="Who's Using Vetcove?"
      description={`Over ${facts.clinicCount} clinics use Vetcove on a daily basis to make ordering easier. See how these clinics use Vetcove to simplify their ordering!`}
      link='View User Feedback'
      href={routes.customers}
      icon={faCommentAltSmile}
    />
  </>
);

type LearnMoreProps = {
  label: string;
  heading: string;
  renderCards?: () => ReactNode;
};

const LearnMore = (props: LearnMoreProps) => {
  const { label, heading, renderCards = defaultRenderCards } = props;

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.label}>{label}</div>
        <h2 className={styles.heading}>{heading}</h2>
      </div>
      <div className={styles.cards}>{renderCards()}</div>
    </div>
  );
};

export default Object.assign(LearnMore, { Card: LearnMoreCard });
