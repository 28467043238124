import Image, { ImageProps } from 'components/display/Image';

type Image2xProps = Omit<ImageProps, 'src'> & {
  src: StaticImageData;
};

/**
 * Wrapper component for Next's Image component when used with 2x resolution images
 *
 * Sets the width/height based on what would be correct for the 1x resolution image
 */
const Image2x = ({ src, ...rest }: Image2xProps) => (
  <Image src={src} width={src.width / 2} height={src.height / 2} {...rest} />
);

export default Image2x;
