import type { NextPage } from 'next';
import { faPlay } from '@fortawesome/pro-solid-svg-icons';
import {
  faArrowRight,
  faClipboardPrescription,
  faShoppingCart,
} from '@fortawesome/pro-regular-svg-icons';
import { useState } from 'react';

import Image from 'components/display/Image';
import videos from 'constants/videos';
import Page from 'components/layout/Page';
import SignupCtaBanner from 'components/interactive/SignupCtaBanner';
import Button from 'components/interactive/Button';
import Icon from 'components/display/Icon';
import { Testimonial, TESTIMONIAL_DATA } from 'constants/testimonials';
import Image2x from 'components/display/Image2x';
import VideoModal from 'components/overlay/VideoModal';
import Divider from 'components/layout/Divider';
import Hexagon from 'components/display/Hexagon';
import LearnMore from 'components/interactive/LearnMore';
import AnchorLink from 'components/interactive/AnchorLink';
import routes from 'constants/routes';
import facts from 'constants/facts';
import LinkButton from 'components/interactive/LinkButton';

import middleHex from './images/middle-hex.svg';
import middleHexDark from './images/middle-hex-dark.svg';
import rightHexes from './images/right-hexes.svg';
import leftHexes from './images/left-hexes.svg';
import underline from './images/underline.svg';
import arrow from './images/arrow.svg';
import hexes from './images/hexes.svg';
import line from './images/line.svg';
import avmaLogo from './images/avma-logo.svg';
import aaepLogo from './images/aaep-logo.svg';
import aafpLogo from './images/aafp-logo.svg';
import placeholder from './images/placeholder.png';
import mobileBg from './images/mobile-bg.svg';

import styles from './index.module.scss';

const Banner = () => {
  return (
    <div className={styles.banner}>
      <span className={styles.tag}>New</span>
      <span className={styles.text}>
        The AVMA and Vetcove have teamed up to launch AVMA Direct Connect!
      </span>
      <LinkButton href={routes.external.avma} className={styles.link} hasArrow={true}>
        Read our blog post
      </LinkButton>
    </div>
  );
};

const Hero = () => {
  return (
    <div className={styles.hero}>
      <div className={styles.content}>
        <h1 className={styles.heading}>
          The{' '}
          <span className={styles.highlight}>
            all-in-one
            <div className={styles.underline}>
              <Image src={underline} />
            </div>
          </span>
          <br /> platform to modernize your vet practices
        </h1>
        <p className={styles.description}>
          Cut ordering time in half and save on supplies with the vendors you already use.
          Completely free to veterinary clinics &amp; nonprofits.
        </p>
        <div className={styles.actions}>
          <Button href={routes.external.signUp} variant='secondary' hasArrow={true}>
            Get Started
          </Button>
        </div>
      </div>
      <div className={styles.imageContent}>
        <div className={styles.image}>
          <Image2x src={placeholder} layout='fixed' />
        </div>
      </div>
    </div>
  );
};

const Organizations = () => {
  return (
    <div className={styles.organizations}>
      <div className={styles.mobileLine}>
        <div className={styles.lineHighlight} />
      </div>
      <p className={styles.label}>
        The Official Purchasing Platform of Trusted Veterinary Organizations
      </p>
      <div className={styles.logos}>
        <div className={styles.mobileLine}>
          <div className={styles.lineHighlight} />
        </div>
        <div className={styles.line}>
          <Image src={line} />
        </div>
        <div className={styles.logo}>
          <Image src={avmaLogo} alt='AVMA' height={43} width={162} />
        </div>
        <div className={styles.line}>
          <Image src={line} />
        </div>
        <div className={styles.logo}>
          <Image src={aaepLogo} alt='AAEP' height={59} width={173} />
        </div>
        <div className={styles.line}>
          <Image src={line} />
        </div>
        <div className={styles.logo}>
          <Image src={aafpLogo} alt='AAFP' height={59} width={129} />
        </div>
        <div className={styles.line}>
          <Image src={line} />
        </div>
      </div>
      <div className={styles.mobileLine}>
        <div className={styles.lineHighlight} />
      </div>
    </div>
  );
};

const Procurement = () => {
  return (
    <div className={styles.procurement}>
      <p className={styles.label}>Procurement Marketplace</p>
      <div className={styles.content}>
        <div className={styles.icon}>
          <Hexagon width={70}>
            <Icon className={styles.icon} icon={faShoppingCart} />
          </Hexagon>
        </div>
        <div>
          <h2 className={styles.heading}>Procurement Marketplace</h2>
          <p className={styles.description}>
            Vetcove is the purchasing platform and prescription management, and pharmacy
            infrastructure for veterinary clinics.
          </p>
        </div>
        <div className={styles.actions}>
          <Button href={routes.marketplace.clinics} hasArrow={true}>
            View All Features
          </Button>
        </div>
      </div>
      <div className={styles.image}>
        <Image2x src={placeholder} />
      </div>
    </div>
  );
};

const Pharmacy = () => {
  return (
    <div className={styles.pharmacy}>
      <p className={styles.label}>New: Pharmacy Platform</p>
      <div className={styles.content}>
        <div className={styles.icon}>
          <Hexagon width={70}>
            <Icon className={styles.icon} icon={faClipboardPrescription} />
          </Hexagon>
        </div>
        <div>
          <h2 className={styles.heading}>Pharmacy Management Portal</h2>
          <p className={styles.description}>
            Our new omnichannel pharmacy platform helps clinics power everything from pick-ups to
            home delivery (and every prescription in between).
          </p>
        </div>
        <div className={styles.actions}>
          <Button href={routes.pharmacy.clinics} hasArrow={true}>
            View All Features
          </Button>
        </div>
      </div>
      <div className={styles.image}>
        <Image2x src={placeholder} />
      </div>
    </div>
  );
};

const WhosUsing = () => {
  const [isVideoVisible, setIsVideoVisible] = useState(false);

  return (
    <div className={styles.whosUsing}>
      <div className={styles.content}>
        <p className={styles.label}>Who&apos;s Using</p>
        <h2 className={styles.heading}>
          Over <span className={styles.highlight}>{facts.clinicCount}</span> veterinarian clinics
          trust Vetcove for their purchasing
        </h2>
        <button
          type='button'
          className={styles.videoButton}
          onClick={() => setIsVideoVisible(true)}
        >
          <div className={styles.playIcon}>
            <Icon icon={faPlay} />
          </div>
          <div>
            <p className={styles.buttonLabel}>Our Users</p>
            <p className={styles.buttonLink}>
              Watch the Video <Icon icon={faArrowRight} />
            </p>
          </div>
        </button>
      </div>
      <VideoModal
        title="Who's using Vetcove"
        video={videos.testimonial}
        isVisible={isVideoVisible}
        onClose={() => setIsVideoVisible(false)}
      />
    </div>
  );
};

type TestimonialCardProps = {
  testimonial: Testimonial;
};

const TestimonialCard = ({ testimonial }: TestimonialCardProps) => {
  return (
    <AnchorLink href={routes.customers} className={styles.card} key={testimonial.id}>
      <div className={styles.top}>
        <div className={styles.quote}>“</div>
        <div className={styles.type}>{testimonial.type}</div>
      </div>
      <div className={styles.reviewSection}>
        <div className={styles.review}>
          {testimonial.review} <div className={styles.fadeOut} />
        </div>
        <div>
          <p className={styles.doctor}>{testimonial.doctor}</p>
          <p className={styles.clinic}>{testimonial.clinic}</p>
        </div>
      </div>
    </AnchorLink>
  );
};

const Testimonials = () => {
  const testimonials = TESTIMONIAL_DATA.slice(0, 16);
  const half = Math.round(testimonials.length / 2);
  const topRow = testimonials.slice(0, half);
  const bottomRow = testimonials.slice(-half);

  return (
    <div className={styles.testimonials}>
      <div className={styles.extra}>
        (that&apos;s 44% of all clinics in the US!)
        <div className={styles.arrow}>
          <Image src={arrow} />
        </div>
      </div>
      <div className={styles.overlay} />
      <div className={styles.cards}>
        <div className={styles.topRow}>
          {topRow.map((testimonial) => (
            <TestimonialCard testimonial={testimonial} key={testimonial.id} />
          ))}
        </div>
        <div className={styles.bottomRow}>
          {bottomRow.map((testimonial) => (
            <TestimonialCard testimonial={testimonial} key={testimonial.id} />
          ))}
        </div>
      </div>
    </div>
  );
};

const Home: NextPage = () => {
  return (
    <Page
      title='Veterinary Supply Starts Here'
      headerTheme='dark'
      banner={<Banner />}
      cta={<SignupCtaBanner />}
    >
      <main className={styles.main}>
        <div className={styles.sectionOne}>
          <div className={styles.hexes}>
            <Image src={hexes} layout='fixed' />
          </div>
          <div className={styles.overlay} />
          <div className={styles.mobileBg}>
            <Image src={mobileBg} layout='responsive' />
          </div>
          <div className={styles.container}>
            <Hero />
          </div>
          <Divider
            shape='triangleAsymetrical'
            height={151}
            invert={true}
            position='bottom'
            className={styles.divider}
          />
          <Divider
            shape='triangleAsymetrical'
            height={65}
            invert={true}
            position='bottom'
            className={styles.mobileDivider}
          />
        </div>
        <div className={styles.sectionTwo}>
          <div className={styles.hex}>
            <Image src={middleHex} />
          </div>
          <div className={styles.hexes}>
            <Image src={rightHexes} />
          </div>
          <div className={styles.container}>
            <Organizations />
            <Procurement />
          </div>
        </div>
        <div className={styles.sectionThree}>
          <div className={styles.hex}>
            <Image src={middleHexDark} />
          </div>
          <div className={styles.hexes}>
            <Image src={leftHexes} />
          </div>
          <Divider
            shape='triangleAsymetrical'
            invert={true}
            height={140}
            position='top'
            className={styles.dividerTop}
          />
          <Divider
            shape='triangleAsymetrical'
            invert={true}
            height={60}
            position='top'
            className={styles.mobileDividerTop}
          />
          <div className={styles.container}>
            <Pharmacy />
          </div>
          <Divider
            shape='triangleAsymetrical'
            invert={true}
            height={100}
            position='bottom'
            className={styles.dividerBottom}
          />
          <Divider
            shape='triangleAsymetrical'
            invert={true}
            height={54}
            position='bottom'
            className={styles.mobileDividerBottom}
          />
        </div>
        <div className={styles.sectionFour}>
          <div className={styles.container}>
            <WhosUsing />
          </div>
          <Testimonials />
          <div className={styles.container}>
            <div className={styles.learnMore}>
              <LearnMore label='Get Started' heading='Want to learn more?' />
            </div>
          </div>
        </div>
      </main>
    </Page>
  );
};

export default Home;
